export default {
  state: {
    apiUrl: 'https://api.komservice.net',
    progresLineShow: false,
    errorAlertShow: false,
    errorMessage: '',
    
  },
  mutations: {
    PROGRESLINEDHOWCH (state, progresLineShow) {
      state.progresLineShow = progresLineShow
    },
    ERRORALERTSHOWCH (state, errorAlertShow) {
      state.errorAlertShow = errorAlertShow
    },
    ERRORMESSAGECH (state, errorMessage) {
      state.errorMessage = errorMessage
    },


  },
  actions: {
    PROGRESLINEDHOWCH({commit}, payload){
      commit('PROGRESLINEDHOWCH', payload.progresLineShow)
    },
    ERRORALERTSHOWCH({commit}, payload){
      if (payload.errorAlertShow == true) {
        commit('ERRORALERTSHOWCH', true)
      }
      if (payload.errorAlertShow == false) {
        commit('ERRORALERTSHOWCH', false)
        commit('ERRORMESSAGECH', '')
      }
    },
    ERRORMESSAGECH({commit}, payload){
      commit('ERRORMESSAGECH', payload.errorMessage)
    },

  },
  getters: {
    apiUrl: state => state.apiUrl,
    progresLineShow: state => state.progresLineShow,
    errorAlertShow: state => state.errorAlertShow,
    errorMessage: state => state.errorMessage,
    
  }
}