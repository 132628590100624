import Vue from 'vue'
import Vuex from 'vuex'
import globalModule from '../store/global.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    g: globalModule,
    
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
})
