import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/internet',
  //   name: 'Internet',
  //   //component: () => import('../views/Internet.vue'),
  //   beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //   children: [
  //     {
  //       path: 'cardlist',
  //       //component: () => import('../components/internet/cardlist.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //     },
  //     {
  //       path: 'tarifs',
  //       //component: () => import('../components/internet/tarifs/Tarifs.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet/tarifs/home") },
  //       children: [
  //         {
  //           path: 'home',
  //           //component: () => import('../components/internet/tarifs/home.vue'),
  //           beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //         },
  //         {
  //           path: 'business',
  //           //component: () => import('../components/internet/tarifs/business.vue'),
  //           beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //         }
  //       ]
  //     },
  //     {
  //       path: 'pay',
  //       //component: () => import('../components/internet/pay/pay.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet/pay") },
  //     },
  //     {
  //       path: 'userstat',
  //       //component: () => import('../components/internet/userstat/userstat.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //     },
  //     {
  //       path: 'servicec',
  //       //component: () => import('../components/internet/servicec/servicec.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //     },
  //     {
  //       path: 'docs',
  //       //component: () => import('../components/internet/docs/docs.vue'),
  //       beforeEnter() { window.location.replace("https://ovruch.uar.net/internet") },
  //     },
      
  //   ]
  // },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue'),
  },
  {
    path: '*',
    component: () => import('../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
